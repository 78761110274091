import React from 'react';
import Helmet from 'react-helmet';
import {GlobalStyles} from 'twin.macro';

import Theme from '@/components/theme';


interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const Layout: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			{props.children}
		</Theme>
	);
};

export default Layout;
